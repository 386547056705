.postItem{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-around;
    padding: 3px 6px;
    margin-bottom: 6px;
    border: 1px solid black;
}
p{
    margin-bottom: 4px;
}